import React from "react";
import "./Diyhero.css";
import diyheroimg from "../../Assets/2nd design assets/diyhero.svg";
import appstore from "../../Assets/App Store.png";
import playstore from "../../Assets/Play Store.png";
import oneclick from "../../Assets/2nd design assets/oneclick.svg";
import aiediting from "../../Assets/2nd design assets/aiediting.svg";
import instant from "../../Assets/2nd design assets/instant1.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Diyhero = () => {
  return (
    <>
      <div className="diyherocontainer text-white" id="diycamera">
        <div className="diycontent flex justify-center">
          <div className="diyleft">
            <div className="diytitle">DIY Camera</div>
            <div className="diydesc">Join Our photograppher network</div>
            <Link to="/diycamera">
              <button className="flex items-center  text-white border-0 w-auto focus:outline-none hover:bg-gray-700 rounded buttonbg uppercase ">
                Learn More
              </button>
            </Link>
            <div className=" flex overflow-x-hidden w-auto gap-5">
              <h1 className="appscommingsson">Our Apps Are Commingsoon</h1>
              {/* <motion.img
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, ease: "easeOut" }}
                viewport={{ once: true }}
                className="appstore "
                src={appstore}
                alt=""
              />
              <motion.img
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, ease: "easeOut" }}
                viewport={{ once: true }}
                className="playstore "
                src={playstore}
                alt=""
              /> */}
            </div>
            <div className="diyfeaturecontainer">
              <div className="oneclick flex gap-5 items-center">
                <img src={oneclick} alt="" />
                <p>One click AI HDR Photos</p>
              </div>

              <div className="twoclick flex gap-5 items-center ">
                <img src={aiediting} alt="" />
                <p>AI Editing Technologies </p>
              </div>
              <div className="threeclick flex gap-5 items-center ">
                <img src={instant} alt="" />
                <p>Instant Virtual Staging</p>
              </div>
            </div>
          </div>
          <div className="diyright">
            <img src={diyheroimg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Diyhero;
