import React from "react";
import "./Howitworkphotographer.css";
import arrow1 from "../../Assets/2nd design assets/arrow.svg";
import arrow2 from "../../Assets/2nd design assets/arrow2.svg";

import hitprofile from "../../Assets/2nd design assets/hitpprofile.png";
import hitpcam from "../../Assets/2nd design assets/hitpcam.svg";
import hitpmoney from "../../Assets/2nd design assets/hitpmoney.svg";

const Howitworkphotographer = () => {
  return (
    <>
      <div className="hitphotographercontainer" id="howitwork">
        <div className="hitpuppercontainer">How It Works ?</div>
        <div className="hitpbelowcontainer">
          <img src={arrow1} alt="" className="arrow" />
          <img src={arrow2} alt="" className="arrow" />

          <div className="hitpcard1">
            <img src={hitprofile} alt="" />
            <div className="hitpcardtitle">Sign up in listing pix</div>
            <div className="hitpcarddesc">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </div>
          </div>
          <div className="hitpcard1">
            <img src={hitpcam} alt="" />
            <div className="hitpcardtitle">Click High quality photos</div>
            <div className="hitpcarddesc">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking.
            </div>
          </div>
          <div className="hitpcard1">
            <img src={hitpmoney} alt="" />
            <div className="hitpcardtitle">Easy to earn money</div>
            <div className="hitpcarddesc">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworkphotographer;
